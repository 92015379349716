import React,{Suspense} from "react";
import { createRoot } from "react-dom/client";
import { Routes, Route, HashRouter } from "react-router-dom";
import 'react-toastify/dist/ReactToastify.css';
const AttendanceApp = React.lazy(() => import("./AttendanceApp"));
const MarkedAttendanceApp = React.lazy(() => import("./AttendanceAppMarked"));
const TimeSheet = React.lazy(() => import("./TimeSheet"));
const RenderQuestions = React.lazy(() => import("./RenderQuestions"));
const Editor = React.lazy(() => import("./Editor"));
const QuizApp1 = React.lazy(() => import("./QuizApp1"));
const StartQuiz = React.lazy(() => import("./Quiz/startQuiz"));
const AccessQuiz = React.lazy(() => import("./Quiz/accessQuiz"))
const rootElement = document.getElementById("root");
const root = createRoot(rootElement);
const MainApp = () => {
  return (
    <Routes>
      
        <Route path="/editor" element={<Suspense><Editor /></Suspense>} />
        <Route path="/questions-render" element={<Suspense><RenderQuestions /></Suspense>} />
        <Route path="/timesheet" element={<Suspense><TimeSheet /></Suspense>} />
        <Route path="/marked" element={<Suspense><MarkedAttendanceApp /></Suspense>} />
        <Route path="/start-quiz" element={<Suspense><StartQuiz /></Suspense>} />
        <Route path="/access-quiz" element={<Suspense><AccessQuiz /></Suspense>} />
        <Route path="/quiz-1" element={<Suspense><QuizApp1 /></Suspense>} />
        {/* <Route path="/" element={<Suspense><AttendanceApp /></Suspense>} /> */}
        <Route path="/" element={<Suspense><AccessQuiz /></Suspense>} />
      
    </Routes>
  );
};

root.render(
  <HashRouter>
    <MainApp />
  </HashRouter>
);
